.middle-ware-wrapper[data-v-87bb01a4] {
  min-height: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.middle-ware-wrapper .el-icon-success[data-v-87bb01a4] {
    color: #409eff;
    margin-right: 10px;
}
.middle-ware-wrapper .el-icon-error[data-v-87bb01a4] {
    color: red;
    margin-right: 10px;
}
